import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../core/api.service';

@Injectable()
export class ContentBlockService {
  constructor(private apiService: ApiService) {}

  getContentBlock(
    section: string,
    id: number | string,
    title: string,
    serviceAreaId: number | string,
  ): Observable<{ html: string | null }> {
    return this.apiService.get(
      `/content/guaranteed?section=${section}&page=${id}&title=${title}&service_area_id=${serviceAreaId}`,
    );
  }
}
