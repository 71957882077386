import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, throwError as observableThrowError } from 'rxjs';
import { catchError, shareReplay, take, takeUntil, tap } from 'rxjs/operators';

import { WindowService } from '@app/utils/window.service';

import { ApiHeaderService } from './api-header.service';
import { AttemptedPathService } from './attempted-path.service';
import { AuthService } from './auth.service';
import { ConfigService } from './config.service';
import { FeatureFlags } from './feature-flags/feature-flags';
import { LaunchDarklyService } from './feature-flags/launchdarkly.service';
import { TargetUserService } from './target-user.service';

@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
    private apiHeaderService: ApiHeaderService,
    private config: ConfigService,
    private authService: AuthService,
    private attemptedPathService: AttemptedPathService,
    private targetUserService: TargetUserService,
    private windowService: WindowService,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  private destroy$: Subject<void> = new Subject<void>();

  get<T>(path: string, ignoreUnauthorized = false, body: Record<string, any> = {}) {
    const headers = this.getHeaders();
    return this.http
      .get<T>(`${this.config.json.apiServer}${path}`, {
        headers: headers,
        params: body,
        withCredentials: true,
      })
      .pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
        takeUntil(this.destroy$),
        catchError((error, _caught) => this.handleHttpError(error, ignoreUnauthorized)),
      );
  }

  post<T>(path: string, body: Record<string, any>, ignoreUnauthorized = false) {
    const headers = this.getHeaders();
    return this.http
      .post<T>(`${this.config.json.apiServer}${path}`, body, {
        headers: headers,
        withCredentials: true,
      })
      .pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
        takeUntil(this.destroy$),
        catchError((error, _caught) => this.handleHttpError(error, ignoreUnauthorized)),
      );
  }

  patch(path: string, body: Record<string, any>, ignoreUnauthorized = false) {
    const headers = this.getHeaders();
    return this.http
      .patch(`${this.config.json.apiServer}${path}`, body, {
        headers: headers,
        withCredentials: true,
      })
      .pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
        takeUntil(this.destroy$),
        catchError((error, _caught) => this.handleHttpError(error, ignoreUnauthorized)),
      );
  }

  put(path: string, body: Record<string, any>, ignoreUnauthorized = false) {
    const headers = this.getHeaders();
    return this.http
      .put(`${this.config.json.apiServer}${path}`, body, {
        headers: headers,
        withCredentials: true,
      })
      .pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
        takeUntil(this.destroy$),
        catchError((error, _caught) => this.handleHttpError(error, ignoreUnauthorized)),
      );
  }

  delete(path: string, body: Record<string, any> = {}, ignoreUnauthorized = false) {
    const headers = this.getHeaders();
    return this.http
      .delete(`${this.config.json.apiServer}${path}`, {
        headers: headers,
        params: body,
        withCredentials: true,
      })
      .pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
        takeUntil(this.destroy$),
        catchError((error, _caught) => this.handleHttpError(error, ignoreUnauthorized)),
      );
  }

  unsubscribe(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getHeaders(): HttpHeaders {
    let headers = this.apiHeaderService.headers;
    const targetUser = this.targetUserService.getTargetUser();
    if (targetUser) {
      headers = headers.append('X-Target-User', targetUser.fhirIdentifier);
    }
    return headers;
  }

  private handleHttpError(error: any, ignoreUnauthorized: boolean) {
    if (error.status === 401 && !ignoreUnauthorized) {
      this.launchDarklyService
        .featureFlag$<boolean>(FeatureFlags.PC_REDIRECT_TO_FULL_HREF_ON_HTTP_401_ERRORS_IN_API_SERVICE, false)
        .pipe(
          take(1),
          tap(includeMoreUrlParts => {
            const path = includeMoreUrlParts
              ? this.windowService.getLocationHrefWithoutOrigin()
              : this.windowService.getLocationPathname();

            this.attemptedPathService.setAttemptedPath(path);
            this.unsubscribe();
            this.authService.logout(path);
          }),
        )
        .subscribe();
    }
    return observableThrowError(error);
  }
}
