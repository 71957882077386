import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import { ApiService } from '@app/core/api.service';
import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { UserService } from '@app/core/user.service';
import { RealtimeCommunicationService } from '@app/shared/realtime-communication.service';

export interface UpdateCreditCardResponse {
  success: boolean;
  message: string;
}

const STRIPE_SOURCE_UPDATE_COMPLETE_EVENT_NAME = 'stripe_source_update_complete';

@Injectable()
export class UpdateCreditCardService implements OnDestroy {
  private subscriptionChannelId = 'pt-';
  private destroy$ = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private featureFlagSelectors: FeatureFlagSelectors,
    private realtimeCommunicationService: RealtimeCommunicationService,
    private userService: UserService,
  ) {
    this.userService
      .getUser()
      .pipe(take(1))
      .subscribe(user => {
        this.subscriptionChannelId = `pt-${user.id}`;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  listenToSubscriptionEvents(): Observable<unknown> {
    const subscriptionEventsListener$ = new Subject<UpdateCreditCardResponse>();

    this.featureFlagSelectors
      .getFeatureFlag(FeatureFlags.CONSUMER_REGISTRATION_APP_SYNC_COMMUNICATION, false)
      .pipe(takeUntil(this.destroy$))
      .subscribe(consumerRegistrationAppSyncFlag => {
        if (consumerRegistrationAppSyncFlag) {
          return this.onAppSyncSubscriptionCreate(subscriptionEventsListener$);
        } else {
          return this.onPusherSubscriptionCreate(subscriptionEventsListener$);
        }
      });
    return subscriptionEventsListener$.asObservable();
  }

  updateCreditCard(stripeTokenId: string, coupon?: string): Observable<void> {
    const params: Record<string, string> = { stripe_token: stripeTokenId };
    if (coupon) {
      params.coupon = coupon;
    }

    const updateCreditCardResponse = new Subject<void>();
    this.apiService
      .patch('/api/v2/patient/memberships/credit_card', params)
      .pipe(take(1))
      .subscribe({
        next: () => updateCreditCardResponse.next(),
        error: error => updateCreditCardResponse.error(error),
      });

    return updateCreditCardResponse.asObservable();
  }

  private onAppSyncSubscriptionCreate(subscriptionEventsListener$: Subject<UpdateCreditCardResponse>) {
    this.realtimeCommunicationService
      .getAppSyncResponse<UpdateCreditCardResponse>('Membership', 'UPDATE', this.subscriptionChannelId)
      .pipe(
        take(1),
        map((response: UpdateCreditCardResponse) => this.handleError(response)),
      )
      .subscribe({
        next: response => {
          subscriptionEventsListener$.next(response);
          subscriptionEventsListener$.complete();
        },
        error: error => {
          subscriptionEventsListener$.error(error);
          subscriptionEventsListener$.complete();
        },
      });
  }

  private onPusherSubscriptionCreate(subscriptionEventsListener$: Subject<UpdateCreditCardResponse>) {
    this.realtimeCommunicationService
      .getResponse<UpdateCreditCardResponse>(this.subscriptionChannelId, STRIPE_SOURCE_UPDATE_COMPLETE_EVENT_NAME)
      .pipe(map((response: UpdateCreditCardResponse) => this.handleError(response)))
      .subscribe({
        next: response => {
          subscriptionEventsListener$.next(response);
          subscriptionEventsListener$.complete();
        },
        error: error => {
          subscriptionEventsListener$.error(error);
          subscriptionEventsListener$.complete();
        },
      });
  }

  private handleError(response?: UpdateCreditCardResponse) {
    if (response === undefined) {
      throw new Error('Something went wrong');
    }
    if (!response.success) {
      throw new Error(response.message);
    }

    return response;
  }
}
